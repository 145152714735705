import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@melio/billpay-design-system';
import { CONSTS } from 'src/utils/consts';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import QBONotificationCard from 'src/components/qbo/QBONotificationCard';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlags } from 'src/utils/feature-flags';

export const NotificationCardUnreadyBills: React.FC = () => {
  const [visaMccVerificationEnabled] = featureFlags.useFeature(FeatureFlags.VisaMccVerification, false);
  const readyBillsCount = useSelector(regularBatchPaymentsStore.selectors.readyBillsCount(visaMccVerificationEnabled));
  const totalCount = useSelector(regularBatchPaymentsStore.selectors.totalCount);
  const numberUnreadyBills = totalCount - readyBillsCount;

  return (
    <Box data-testid="batchPayment.notifications.warning.unreadyPayments" mt="ds.xl">
      <QBONotificationCard
        type={CONSTS.NOTIFICATION_CARD_TYPES.WARNING}
        title={{
          label: 'regularBatchPayments.notifications.unreadyBillsTitle',
          values: { numberUnreadyBills },
        }}
        subtitle={{
          label: 'regularBatchPayments.notifications.unreadyBillsSubtitle',
          values: { numberUnreadyBills },
        }}
      />
    </Box>
  );
};
