import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { featureFlags } from '@melio/shared-web';
import locations from 'src/billpay/qbdt/pages/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { FeatureFlags } from 'src/utils/feature-flags';
import { catchAsync } from 'src/utils/async';
import { CREATE_BATCH_PAYMENTS_ACTION_NAME } from 'src/modules/regular-batch-payments/regular-batch-payments-create-slice';
import { getValidationFailureActionName } from 'src/helpers/redux/createApiCallSlice';
import { useRedirectToDashboard } from 'src/billpay/qbdt/pages/dashboard/hooks/useRedirectToDashboard';
import {
  DASHBOARD_ACTION_CONFIRM_FLOW_STATE_PROP,
  DashboardActionConfirmFlowEnum,
} from 'src/billpay/qbdt/pages/dashboard/consts';
import { PaymentType } from 'src/utils/types';
import { useCheckFee } from 'src/billpay/qbdt/hooks/useCheckFee';
import { BatchSyncError, useCreateBatchPayments } from '../hooks/useCreateBatchPayments';
import { getDashboardItemId } from '../../dashboard/utils';
import useGetValidFundingSources from '../table/hooks/useGetValidFundingSources';
import useBatchBillsList from './useBatchBillsList';

const getDashboardItemIdsFromPayments = (payments: Partial<PaymentType>[]) =>
  payments.map(({ id, billPayments }) => {
    const billIds = billPayments?.map(({ billId }) => billId);
    const paymentId = id;

    return getDashboardItemId(billIds, paymentId);
  });

type ScheduleBatchPaymentsParams =
  | {
      onCreateBatchValidationError?: () => void;
      onNoPaymentsScheduled?: () => void;
    }
  | undefined;

export const useScheduleBatchPayments = () => {
  const [isDashboardFeatureEnabled] = featureFlags.useFeature(FeatureFlags.Dashboard, false);
  const [validFundingSources] = useGetValidFundingSources();
  const checkFee = useCheckFee();
  const { billsList } = useBatchBillsList();
  const history = useHistory();

  const orgId = useSelector(getOrgId);
  const { createBatchPaymentAction } = useCreateBatchPayments();

  const { redirectToDashboard } = useRedirectToDashboard();

  const scheduleBatchPayments = async ({
    onCreateBatchValidationError,
    onNoPaymentsScheduled,
  }: ScheduleBatchPaymentsParams = {}) => {
    const [exception, createBatchPaymentsResponse] = await catchAsync(createBatchPaymentAction());
    const createBatchValidationActionType = getValidationFailureActionName(CREATE_BATCH_PAYMENTS_ACTION_NAME);

    if ((exception as any)?.type === createBatchValidationActionType) {
      onCreateBatchValidationError?.();

      return;
    }

    const isSyncError = exception instanceof BatchSyncError;

    if (exception && !isSyncError) {
      history.push(generatePath(locations.batchPay.batchError, { orgId }));

      return;
    }

    checkFee.increaseCheckFeePaymentCount({
      batchData: billsList,
      batchFundingSources: validFundingSources,
    });

    const scheduledPayments = isSyncError
      ? (exception as BatchSyncError).scheduledPayments
      : createBatchPaymentsResponse?.scheduledPayments;
    const arePaymentsScheduled = scheduledPayments?.length > 0;

    if (!arePaymentsScheduled) {
      onNoPaymentsScheduled?.();
      return; // TODO: dashboard - it worked with the same logic, but it might be needed to be handled also
    }

    if (isDashboardFeatureEnabled) {
      redirectToDashboard({
        highlightedItemIds: getDashboardItemIdsFromPayments(scheduledPayments),
        paymentId: scheduledPayments?.[0]?.id,
        state: {
          [DASHBOARD_ACTION_CONFIRM_FLOW_STATE_PROP]: DashboardActionConfirmFlowEnum.BatchPay,
          isSyncPaymentsFailed: isSyncError,
          scheduledPayments: scheduledPayments.map(({ id, amount, vendorId }) => ({ id, amount, vendorId })),
        },
      });

      return;
    }

    history.push(generatePath(locations.batchPay.batchSuccess, { orgId }), { isSyncPaymentsFailed: isSyncError });
  };

  return {
    scheduleBatchPayments,
  };
};
