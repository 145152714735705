import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import QBOLink from 'src/theme/ds/link';
import profileStore from 'src/modules/profile/profile-store';
import analytics from 'src/services/analytics';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { ModalContentContainer } from 'src/components/common/ModalMessage/ModalMessage.styles';
import { MIFormattedText } from 'src/utils/formatting';
import { CONSTS } from 'src/utils/consts';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { useApi } from 'src/hoc/useApi';
import userApi from 'src/services/api/user';
import organizationApi from 'src/services/api/organizations';
import { pushNotification } from 'src/services/notifications/notificationService';
import { validateMultipleFields } from 'src/utils/form-utils';
import vendorsApi from 'src/services/api/vendors';
import MIButton from 'src/components/common/MIButton';
import MISingleSelect from 'src/components/common/MISingleSelect';
import { DeliveryMethodType, GoogleCombinedAddressType } from 'src/utils/types';
import { hasCheckDeliveryMethod } from 'src/billpay/qbdt/utils/deliveryMethodUtils';
import { BATCH_PAGE_EVENT } from 'src/billpay/qbdt/pages/batch-pay/analytics/event-mapping';
import { mapAllowedMCCsToOptions } from './utils';

export type CodeChangeProps = {
  vendorId: number;
  mccCode: string;
};

type Props = {
  orgId: string;
  billIds: number[];
  vendorId: number;
  vendorName: string;
  analyticsProps: {
    billIds: number[] | undefined;
    vendorId: number | undefined;
  };
  isBatch?: boolean;
  deliveryMethods: Array<DeliveryMethodType>;
  onSubmit?: () => void;
  onClose: (isBatch?: boolean) => void;
  onCodeChange: (props: CodeChangeProps) => void;
};

export const Modal = ({
  orgId,
  billIds,
  vendorId,
  vendorName,
  analyticsProps,
  isBatch,
  deliveryMethods,
  onSubmit,
  onClose,
  onCodeChange,
}: Props) => {
  const currentUser = useSelector(profileStore.selectors.profile);

  const [industries, setIndustries] = useState<{ value: string; label: string }[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [address, setAddress] = useState<GoogleCombinedAddressType | null>(null);
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>({});

  const [getAllowedMCCList, , isMCCListLoading] = useApi(organizationApi.getAllowedMCCList);
  const [updateVendorMCCCode, , isVendorMCCCodeUpdating] = useApi(vendorsApi.updateVendorMCCCode);
  const [editVendor, , isVendorUpdating] = useApi(vendorsApi.editVendorById);

  useEffect(() => {
    loadAllowedMCCs();
    trackVisaModalOpenBackendEvent();

    const eventPage = isBatch ? BATCH_PAGE_EVENT : 'pay-bill';
    analytics.track(eventPage, 'visa-industry-modal_open', analyticsProps);
  }, []);

  const loadAllowedMCCs = async () => {
    try {
      const { list: allowedMCCs } = await getAllowedMCCList(orgId, {
        cardNetwork: 'visa',
      });
      const { mccIndustries } = mapAllowedMCCsToOptions(allowedMCCs);

      setIndustries(mccIndustries);
    } catch (error) {
      // error while loading MCCs list;
    }
  };

  const trackVisaModalOpenBackendEvent = () => {
    try {
      billIds.forEach((billId) => {
        userApi.trackEvent(currentUser.id, 'analytics-mcc-restriction-on-billpay-scheduleBill', {
          table: 'bill',
          id: billId.toString(),
          key: 'batch',
          value: !!isBatch,
        });
      });
    } catch (error) {
      // error while sending backend analytics;
    }
  };

  const handleSubmit = async () => {
    const errors = validateForm({
      industry: selectedIndustry,
      address,
    });

    setValidationErrors(errors);

    if (!isEmpty(errors)) {
      analytics.track('pay-bill', 'visa-industry-modal_click-error', {
        errors,
        selectedCategory: selectedIndustry,
        ...analyticsProps,
      });

      return;
    }

    analytics.track('pay-bill', 'visa-industry-modal_click-save', {
      selectedCategory: selectedIndustry,
      ...analyticsProps,
    });

    try {
      // Update MCC code
      await updateVendorMCCCode({
        orgId,
        vendorId,
        mccCode: selectedIndustry,
      });
      onCodeChange({ vendorId, mccCode: selectedIndustry });

      // Update vendor address if provided
      if (address) {
        const formattedAddress = {
          addressLine1: `${address.addressComponents?.find((c) => c.types.includes('street_number'))?.long_name} ${
            address.addressComponents?.find((c) => c.types.includes('route'))?.long_name
          }`,
          city: address.addressComponents?.find((c) => c.types.includes('locality'))?.long_name,
          state: address.addressComponents?.find((c) => c.types.includes('administrative_area_level_1'))?.short_name,
          zipCode: address.addressComponents?.find((c) => c.types.includes('postal_code'))?.long_name,
          countryCode: address.addressComponents?.find((c) => c.types.includes('country'))?.short_name,
        };

        await editVendor({
          orgId,
          id: vendorId,
          params: {
            address: formattedAddress,
          },
        });
      }

      if (onSubmit) {
        onSubmit();
      }

      setTimeout(() => {
        pushNotification({
          type: CONSTS.NOTIFICATION_VARIANT.SUCCESS,
          msg: 'visaVerification.toast',
          textValues: { vendorName },
        });
      });

      onClose(isBatch);
    } catch (error) {
      // error while updating vendor's MCC or address
    }
  };

  const handleIndustryChange = ({ value: industry }) => {
    setSelectedIndustry(industry);

    analytics.track('pay-bill', 'visa-industry-modal_select-category', {
      selectedCategory: industry,
      ...analyticsProps,
    });
  };

  const validateForm = ({
    industry,
    address,
  }: { industry?: string; address?: GoogleCombinedAddressType | null } = {}) => {
    const fields = [{ name: 'industry', value: industry, isRequired: true }];

    if (!hasCheckDeliveryMethod(deliveryMethods)) {
      // Only validate address if check delivery method is not available
      fields.push({
        name: 'address',
        value: address?.placeId || address?.formattedAddress || undefined,
        isRequired: true,
      });
    }

    return validateMultipleFields(fields, 'visaVerification.modal.form');
  };

  const renderCloseLink = (...chunks) => (
    <QBOLink
      onClick={() => {
        onClose(isBatch);
      }}
      textDecoration="none !important"
      cursor="pointer"
      color="#0077C5"
      fontWeight="500"
    >
      {React.Children.toArray(chunks)}
    </QBOLink>
  );

  const renderEmptyState = (
    <EmptyStateContainer>
      <MIFormattedText
        label="visaVerification.modal.form.emptyState.message"
        values={{
          link: renderCloseLink,
        }}
      />
    </EmptyStateContainer>
  );

  const renderForm = (
    <div>
      <SelectContainer>
        <MISingleSelect
          id="industry"
          value={selectedIndustry}
          label="visaVerification.modal.form.industry.label"
          placeholder="visaVerification.modal.form.industry.placeholder"
          errorMessage={validationErrors.industry}
          onChange={handleIndustryChange}
          options={industries}
          menuPosition="fixed"
          isProcessing={isMCCListLoading}
          isDisabled={isMCCListLoading}
          isSearchable={false}
          required
          noOptionsComponent={renderEmptyState}
          overrideStyles={singleSelectStyles}
        />
      </SelectContainer>
      {!hasCheckDeliveryMethod(deliveryMethods) && (
        <MIAddressAutocomplete
          id="vendor-address"
          addressLabel="Vendor Address"
          aptNumberLabel="Suite/Unit #"
          required
          mode={CONSTS.ADDRESS_FORM_CONTROL_MODE.FORM}
          onChange={setAddress}
          errorMessage={validationErrors.address}
          address={address}
        />
      )}
    </div>
  );

  return (
    <StyledModal
      alignLeft
      titleComponent={<MIFormattedText label="visaVerification.modal.title" values={{ vendorName }} />}
      buttonComponent={
        <ButtonsContainer>
          <MIButton
            label={isBatch ? 'visaVerification.modal.batchSubmit' : 'visaVerification.modal.submit'}
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            isProcessing={isVendorMCCCodeUpdating || isVendorUpdating}
            onClick={handleSubmit}
          />
        </ButtonsContainer>
      }
      contentComponent={renderForm}
      onCloseClick={() => {
        onClose(isBatch);
      }}
    />
  );
};

const singleSelectStyles = {
  menuList: {
    padding: '0',
  },
};

const StyledModal = styled(ModalMessage)`
  ${ModalContentContainer} {
    padding: 4rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SelectContainer = styled.div`
  margin-top: 2.4rem;
`;

const EmptyStateContainer = styled.div`
  padding: 4rem 0;
  color: #8d9096;
  font-size: 1.4rem;
  line-height: 2rem;
`;
