import { DELIVERY_TYPE } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';
import { QBDTAddress } from '../services/qbdt/types';

export const removeCompanyNameFromAddressFields = function (
  address?: QBDTAddress,
  companyName?: string
): QBDTAddress | null {
  if (!address) return null;

  if (!companyName) return address;

  if (address.Addr1 === companyName) {
    if (!address.Addr2) {
      return address;
    }

    return { ...address, ...{ Addr1: address.Addr2, Addr2: '' } };
  }

  if (address.Addr1.startsWith(companyName)) {
    return {
      ...address,
      ...{
        Addr1: address.Addr1.replace(companyName, '').trimStart(),
      },
    };
  }

  return address;
};

export const hasCheckDeliveryMethod = function (deliveryMethods?: Array<DeliveryMethodType>): boolean {
  return !!deliveryMethods?.some((method) => method.deliveryType === DELIVERY_TYPE.CHECK);
};
