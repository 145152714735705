import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { MIFormattedCurrency } from 'src/utils/formatting';
import ErrorPage from 'src/components/layout/ErrorLayoutPage';
import { QBDTLoader } from 'src/billpay/qbdt/components/QBDTLoader';
import failedToLoad from 'src/images/qbo/failed-to-load-002-icon.png';
import analytics from 'src/services/analytics';
import { withNavigator } from 'src/hoc';
import locations from 'src/billpay/qbdt/pages/locations';
import { getBill, getPayment, getErrorCode, getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { useCheckFee } from 'src/billpay/qbdt/hooks/useCheckFee';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { QBDTStepLayout } from 'src/billpay/qbdt/components/QBDTStepLayout';
import useHistoryWithOrgId from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { BUTTON_VARIANT } from 'src/utils/consts';
import { isCompanyInfoDone } from 'src/utils/company';
import { isComplianceInfoDone } from 'src/utils/compliance-utils';
import { useApi } from 'src/hoc/useApi';
import { billingFeeApi, OrganizationBillingFee } from 'src/services/api/smb-billing-fee';
import { withPayBillData } from '../hoc/withPayBillData';
import { useConfirmPageFee } from './hooks/useConfirmPageFee';
import { useOnPaymentSubmit } from '../hooks/useOnPaymentSubmit';
import { useSetDeliveryDates } from './hooks/useSetDeliveryDates';
import { QBDTConfirmPaymentInfo } from './QBDTConfirmPaymentInfo/QBDTConfirmPaymentInfo';
import { ConfirmAgreements } from './components/ConfirmAgreements';
import { useGoEditDate } from './hooks/useGoEditDate';
import { useGoEditNote } from './hooks/useGoEditNote';
import { useComplianceLimitations } from './hooks/useComplianceLimitations';

type HocProps = {
  onPrevConfirmPayment: () => void;
  goExit: () => void;
  onNext: () => void;
  goEditDeliveryMethod: () => void;
  goEditFundingSource: () => void;
};

type Props = HocProps;

const eventPage = 'pay-bill';

export const PayBillConfirmPage = wrapWithCompose(
  ({ goExit, onPrevConfirmPayment, onNext, goEditDeliveryMethod, goEditFundingSource }: Props) => {
    const [historyPush] = useHistoryWithOrgId();
    const bill = useSelector(getBill);
    const payment = useSelector(getPayment);
    const selectedFundingSource = useSelector(getSelectedFundingSource);
    const errorCode = useSelector(getErrorCode);
    const companyInfo = useSelector(getCompanyInfo);
    const orgId = useSelector(getOrgId);
    const checkFee = useCheckFee();
    const [getOrganizationBillingFees, organizationBillingFeeResponse, isFetchingOrgBillingFee] = useApi<
      [{ orgId: number }],
      { organizationBillingFees: OrganizationBillingFee[] }
    >(billingFeeApi.getBillingFees, false, true);

    const { goEditDate } = useGoEditDate();
    const { goEditNote } = useGoEditNote();

    useEffect(() => {
      getOrganizationBillingFees({ orgId });
      setTimeout(() => {
        window.scrollBy(0, 1);
      }, 10);
    }, [orgId]);

    const { fee, isFeeLoading } = useConfirmPageFee();
    const { limitations, isComplianceLimitationsLoading } = useComplianceLimitations();
    const { isProcessingDatesLoading } = useSetDeliveryDates();
    const { onSubmit, isPaymentSubmitting } = useOnPaymentSubmit({
      onNext,
    });

    const submitHandler = () => {
      const shouldRedirectToLegalInfoPage = !isCompanyInfoDone(companyInfo, limitations);
      const shouldRedirectToCompleteComplianceInfoPage = limitations && !isComplianceInfoDone(limitations);

      if (shouldRedirectToLegalInfoPage) {
        analytics.track(eventPage, 'go-to-complete-legal-info');
        historyPush({
          path: generatePath(locations.pay.completeLegalInfo, {
            billId: bill.id,
            orgId,
          }),
        });
      } else if (shouldRedirectToCompleteComplianceInfoPage) {
        analytics.track(eventPage, 'go-to-complete-compliance-info');
        historyPush({
          path: generatePath(locations.pay.completeComplianceInfo, {
            billId: bill.id,
            orgId,
          }),
        });
      } else if (!isCompanyInfoDone(companyInfo)) {
        analytics.track(eventPage, 'go-to-complete-legal-info');
        historyPush({
          path: generatePath(locations.pay.completeLegalInfo, {
            billId: bill.id,
            orgId,
          }),
        });
      } else {
        onSubmit();

        // will be removed after promotions
        checkFee.increaseCheckFeePaymentCount({
          deliveryMethod: payment.deliveryMethod,
          fundingSource: selectedFundingSource,
          deliveryPreference: payment.deliveryPreference,
        });
      }
    };

    const goExitConfirmPayment = () => {
      if (!isPaymentSubmitting) {
        goExit();
      }
    };

    const closeIframe = () => {
      analytics.track('pay-bill-confirm', 'close-integration');

      if (goExit) {
        goExit();
      }
    };

    const selectedDeliveryMethod = bill.getDeliveryMethodById(payment.deliveryMethodId);

    const headerLabelValues = {
      amount: <MIFormattedCurrency value={payment.amount} />,
      companyName: bill.vendor.companyName,
    };

    if (errorCode) {
      analytics.track('bill-error', 'payment-cannot-be-approved');
      return (
        <ErrorPage
          illustration={failedToLoad}
          title="bills.pay.confirm.error.approvingPaymentTitle"
          subtitle="bills.pay.confirm.error.approvingPaymentSubtitle"
          buttonAction={closeIframe}
          buttonLabel="bills.pay.confirm.error.approvingPaymentCTA"
        />
      );
    }

    if (
      !bill.id ||
      isFeeLoading ||
      isProcessingDatesLoading ||
      isFetchingOrgBillingFee ||
      isComplianceLimitationsLoading
    ) {
      return <QBDTLoader />;
    }

    const activityActions = {
      onEditDate: goEditDate,
      onEditDeliveryMethod: goEditDeliveryMethod,
      onEditFundingSource: goEditFundingSource,
      onEditNote: goEditNote,
    };

    return (
      <QBDTStepLayout
        title="bills.pay.confirm.title"
        headerLabel="qbo.header.title"
        nextLabel="bills.pay.confirm.action"
        relativeStep={5 / 5}
        headerLabelValues={headerLabelValues}
        goExit={goExitConfirmPayment}
        onPrev={onPrevConfirmPayment}
        onNext={submitHandler}
        isLoading={isPaymentSubmitting}
        innerSize={50}
        ctaVariant={BUTTON_VARIANT.PAY}
        fullWidthCTA
      >
        <checkFee.NotificationAppliedFee
          deliveryMethod={selectedDeliveryMethod}
          fundingSource={selectedFundingSource}
          deliveryPreference={payment.deliveryPreference}
        />
        <QBDTConfirmPaymentInfo
          bill={bill}
          fee={fee}
          fundingSource={selectedFundingSource}
          payment={payment}
          deliveryMethod={selectedDeliveryMethod}
          activityActions={activityActions}
          checkFee={checkFee}
          organizationBillingFees={organizationBillingFeeResponse?.organizationBillingFees || []}
        />
        <ConfirmAgreements />
      </QBDTStepLayout>
    );
  }
);

function wrapWithCompose(componentFn) {
  return compose(withNavigator(), withPayBillData())(componentFn);
}
