import { createContext, useContext } from 'react';
import { VisaModalVerificationData } from '../hooks/useVisaVerification';

type VisaVerificationContextType = {
  openVisaModal: (data: VisaModalVerificationData) => void;
};

export const VisaVerificationContext = createContext<VisaVerificationContextType>({
  openVisaModal: () => undefined,
});

export const VisaVerificationContextProvider = VisaVerificationContext.Provider;

export const useVisaVerificationContext = () => useContext(VisaVerificationContext);
