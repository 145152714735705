import { useSelector } from 'react-redux';
import { featureFlags } from '@melio/shared-web';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { getOrgId, getFundingSources } from 'src/redux/user/selectors';
import { FeatureFlags } from 'src/utils/feature-flags';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import useBatchBillsList from 'src/billpay/qbdt/pages/batch-pay/hooks/useBatchBillsList';
import { useBatchAnalytics } from 'src/billpay/qbdt/pages/batch-pay/analytics/useBatchAnalytics';
import { PaymentType } from 'src/utils/types';
import { reportPaymentSummaryRequest } from 'src/billpay/qbdt/services/qbdt/adapter/types';
import { reportPaymentSummary, syncPayment, setCanClose } from 'src/billpay/qbdt/services/qbdt';
import { ErrorType, FailedPaymentType } from 'src/modules/regular-batch-payments/types/store-types';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useCombinedBillsToggleStatus } from '../components/header-bulk/components/payments-settings/hooks/useCombinedBillsToggleStatus';

const BatchSyncFailedErrorMessage = 'Batch sync payments failed';

export class BatchSyncError extends Error {
  constructor(scheduledPayments: Partial<PaymentType>[]) {
    super(BatchSyncFailedErrorMessage);
    this.name = 'BatchSyncError';
    this.scheduledPayments = scheduledPayments;
  }

  scheduledPayments: Partial<PaymentType>[];
}

type CreateBatchPaymentDataType = {
  isCreating: boolean;
  error?: ErrorType;
  scheduledPaymentsCount: number;
  scheduledPaymentsAmount: number;
  firstScheduledPaymentBillId: string;
  scheduledPayments: Partial<PaymentType>[];
  failedPayments: FailedPaymentType[];
  failedPaymentsCount: number;
};

type UseCreateBatchPaymentType = {
  createBatchPaymentAction: () => Promise<any>;
  createBatchPaymentData: CreateBatchPaymentDataType;
};

export const useCreateBatchPayments = (): UseCreateBatchPaymentType => {
  const actions = useStoreActions(regularBatchPaymentsStore);
  const orgId = useSelector(getOrgId);
  const site = useSiteContext();
  const fundingSources = useSelector(getFundingSources);

  const [isDashboardFeatureEnabled] = featureFlags.useFeature(FeatureFlags.Dashboard, false);
  const [visaMccVerificationEnabled] = featureFlags.useFeature(FeatureFlags.VisaMccVerification, false);
  const isBillsGroupingPossible = useSelector(regularBatchPaymentsStore.selectors.isBillsGroupingPossible);

  const isCombinedToggleChecked = useCombinedBillsToggleStatus();

  const { billsList } = useBatchBillsList();
  const dataToCreatePayments = billsList.map((item) => ({
    ...item.payment,
    billIds: item.payment.bills.map((bill) => bill.id),
    id: item.id,
    payBillFlowUUID: item.payBillFlowUUID,
  }));
  const batchItemsIds = billsList.map((item) => item.id);
  const { trackScheduleBatchAction } = useBatchAnalytics();
  const createBatchPaymentData: CreateBatchPaymentDataType = useSelector(
    regularBatchPaymentsStore.selectors.createBatchPayment.createBatch(batchItemsIds)
  );

  const syncPayments = async (scheduledPayments: Partial<PaymentType>[]) => {
    try {
      const syncs: Promise<reportPaymentSummaryRequest>[] = [];
      for await (const payment of scheduledPayments) {
        syncs.push(syncPayment({ orgId, paymentId: payment.id! }));
      }

      const summaries = await Promise.all(syncs);

      if (!isDashboardFeatureEnabled) {
        await reportPaymentSummary(Object.assign({}, ...summaries));
      }
    } catch (e) {
      throw new BatchSyncError(scheduledPayments);
    }
  };

  const createBatchPaymentAction = async () => {
    try {
      await setCanClose(false);
      const payments = dataToCreatePayments.map((payment) => {
        const fundingSource = fundingSources.find((fundingSource) => fundingSource.id === payment.fundingSourceId);

        return {
          ...payment,
          createOrigin: site.createOrigin.pay.payment,
          cardAccountNetwork: fundingSource?.cardAccount?.network,
        };
      });

      const payBillFlowUuids = dataToCreatePayments.map(({ payBillFlowUUID }) => payBillFlowUUID);
      const data = await actions.createBatchPayment({
        orgId,
        payments,
        finalToggleState: isCombinedToggleChecked,
        bulkPaymentEligible: isBillsGroupingPossible,
        payBillFlowUuids,
        visaMccVerificationEnabled,
      });

      const scheduledPayments = data.payload.payments;
      const { failedBills } = data.payload;

      trackScheduleBatchAction({ batchTableItems: billsList, failedPayments: failedBills, scheduledPayments });
      if (scheduledPayments.length > 0) {
        await syncPayments(scheduledPayments);
      }

      await setCanClose(true);
      return { scheduledPayments, failedBills };
    } catch (error) {
      await setCanClose(true);
      throw error;
    }
  };

  return {
    createBatchPaymentAction,
    createBatchPaymentData,
  };
};
