export const mapAllowedMCCsToOptions = (allowedMCCs: any[]) => {
  const mccIndustries = allowedMCCs.map((mcc) => ({
    value: mcc.code,
    label: mcc.industry,
  }));

  // Sort industries alphabetically
  mccIndustries.sort((a, b) => a.label.localeCompare(b.label));

  return {
    mccIndustries,
  };
};
