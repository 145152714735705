import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RecordOf } from 'immutable';

import analytics from 'src/services/analytics';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlags } from 'src/utils/feature-flags';
import { AccountType, DeliveryMethodType } from 'src/utils/types';
import vendorsApi from 'src/services/api/vendors';
import { getOrgId } from 'src/redux/user/selectors';
import { isVisaCard } from 'src/utils/card';
import { useApi } from 'src/hoc/useApi';

import { Modal, CodeChangeProps } from './Modal';
import { BATCH_PAGE_EVENT } from '../../pages/batch-pay/analytics/event-mapping';

type VisaModalProps = {
  isBatch?: boolean;
  onSubmit?: () => void;
  onCodeChange?: ({ vendorId, mccCode }: CodeChangeProps) => void;
};

export type VisaModalVerificationData = {
  billIds: number[];
  vendorId: number;
  vendorName: string;
  deliveryMethods: DeliveryMethodType[];
};

const useVisaVerification = () => {
  const orgId = useSelector(getOrgId);

  const [verificationData, setVerificationData] = useState<VisaModalVerificationData | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [MCCCodes, setMCCCodes] = useState({});

  const [getMCCCode, , isLoading] = useApi(vendorsApi.getVendorMCCCode);

  const setVendorMCCCodes = useCallback(
    ({ vendorId, mccCode }) => {
      if (!vendorId || !mccCode) {
        throw new Error();
      }

      setMCCCodes({ ...MCCCodes, [vendorId]: mccCode });
    },
    [MCCCodes]
  );

  const [visaMccVerificationFlag] = featureFlags.useFeature(FeatureFlags.VisaMccVerification, false);

  const shouldDisplayVisaVerification = useCallback(
    async (fundingSource: RecordOf<AccountType> | undefined | null, vendorId: number): Promise<boolean> => {
      if (!visaMccVerificationFlag || !isVisaCard(fundingSource) || !!MCCCodes[vendorId]) {
        return false;
      }

      if (vendorId) {
        const { mccCode } = await getMCCCode(orgId, vendorId);

        return !mccCode;
      }

      return false;
    },
    [orgId, MCCCodes, getMCCCode]
  );

  const handleVisaModalOpen = ({ billIds, vendorId, vendorName, deliveryMethods }: VisaModalVerificationData) => {
    setVerificationData({ billIds, vendorId, vendorName, deliveryMethods });
    setIsOpen(true);
  };

  const handleVisaModalClose = (isBatch?: boolean) => {
    const eventPage = isBatch ? BATCH_PAGE_EVENT : 'pay-bill';

    analytics.track(eventPage, 'visa-industry-modal_close', analyticsProps);

    setIsOpen(false);
    setVerificationData(null);
  };

  const analyticsProps = {
    billIds: verificationData?.billIds,
    vendorId: verificationData?.vendorId,
  };

  const renderVisaModal = ({ isBatch, onSubmit, onCodeChange }: VisaModalProps) => {
    if (!isOpen) return null;

    const handleCodeChange = ({ vendorId, mccCode }: CodeChangeProps) => {
      setVendorMCCCodes({ vendorId, mccCode });

      if (onCodeChange) {
        onCodeChange({ vendorId, mccCode });
      }
    };

    return (
      <Modal
        orgId={orgId}
        billIds={verificationData?.billIds || []}
        vendorId={verificationData?.vendorId as number}
        vendorName={verificationData?.vendorName || ''}
        analyticsProps={analyticsProps}
        isBatch={isBatch}
        deliveryMethods={verificationData?.deliveryMethods || []}
        onSubmit={onSubmit}
        onClose={handleVisaModalClose}
        onCodeChange={handleCodeChange}
      />
    );
  };

  return {
    renderVisaModal,
    openVisaModal: handleVisaModalOpen,
    closeVisaModal: handleVisaModalClose,
    visaLoading: isLoading,
    shouldDisplayVisaVerification,
  };
};

export { useVisaVerification };
