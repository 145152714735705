import React from 'react';
import styled, { css } from 'styled-components';
import { RecordOf } from 'immutable';
import { featureFlags } from '@melio/shared-web';
import { AccountType, DeliveryMethodType } from 'src/utils/types';
import { DELIVERY_TYPE, FUNDING_TYPE } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import { CheckDelaysIcon, CheckDelaysIconVariant } from 'src/components/common/CheckDelaysIcon';
import { useCheckFee } from 'src/billpay/qbdt/hooks/useCheckFee';
import { FeatureFlags } from 'src/utils/feature-flags';

type Props = {
  deliveryMethod: DeliveryMethodType;
  companyName: string;
  onItemClick: () => void;
  isSelected: boolean;
  selectedFundingSource?: RecordOf<AccountType> | undefined;
};

export const QBODeliveryMethodsListItem = ({
  deliveryMethod,
  onItemClick,
  companyName,
  isSelected,
  selectedFundingSource,
}: Props) => {
  const [isCheckDelaysEnabled] = featureFlags.useFeature(FeatureFlags.CheckDelaysWarning, false);
  const checkFee = useCheckFee();
  const getDescription = () => {
    if (deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK) {
      const { description, descriptionValues } = checkFee.getNotice(selectedFundingSource);

      return {
        label: description,
        values: descriptionValues,
      };
    }

    if (deliveryMethod.deliveryType === DELIVERY_TYPE.ACH) {
      if (selectedFundingSource?.fundingType === FUNDING_TYPE.ACH) {
        return {
          label: 'deliveryMethods.notDefinedInfo.ach.withFee',
          values: undefined,
        };
      }

      return {
        label: 'deliveryMethods.notDefinedInfo.ach.withoutFee',
        values: undefined,
      };
    }

    return {
      label: `deliveryMethods.notDefinedInfo.${deliveryMethod.deliveryType}.desc`,
      values: undefined,
    };
  };

  const getDeliveryMethodIcon = () => {
    let icon = '';
    if (isSelected) {
      icon = 'icon-checkmark-icon';
    } else {
      icon = deliveryMethod.deliveryType === DELIVERY_TYPE.ACH ? 'icon-bank-icon' : 'icon-check-icon';
    }

    return icon;
  };

  const displayName = deliveryMethod.id && deliveryMethod.getDisplayName(companyName);
  const shouldShowCheckDelaysWarning = deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK && isCheckDelaysEnabled;

  return (
    <>
      <DeliveryMethod isSelected={isSelected} onClick={onItemClick}>
        <DeliveryMethodDetailsContainer>
          <DeliveryMethodDescriptionContainer>
            <DeliveryMethodIcon
              data-testid={`delivery-method-${deliveryMethod.deliveryType}`}
              isSelected={isSelected}
              className={getDeliveryMethodIcon()}
            />
            <DeliveryMethodInfo>
              <DeliveryMethodName>
                <MIFormattedText
                  label={`deliveryMethods.notDefinedInfo.${deliveryMethod.deliveryType}.label`}
                  values={{
                    to: deliveryMethod.id ? <MIFormattedText label="deliveryMethods.to" /> : '',
                  }}
                />
                {deliveryMethod.id && `(${displayName})`}
                {shouldShowCheckDelaysWarning && <CheckDelaysWarning deliveryMethodId={deliveryMethod?.id} />}
              </DeliveryMethodName>
              <DeliveryMethodDescription>
                <MIFormattedText label={getDescription().label as string} values={getDescription().values} />
              </DeliveryMethodDescription>
            </DeliveryMethodInfo>
          </DeliveryMethodDescriptionContainer>
        </DeliveryMethodDetailsContainer>
      </DeliveryMethod>

      <checkFee.Modal />
    </>
  );
};

const CheckDelaysWarning = ({ deliveryMethodId }) => (
  <CheckDelaysIcon
    tooltipLabel={
      deliveryMethodId ? 'checkDelaysWarning.tooltipLabels.specificCheck' : 'checkDelaysWarning.tooltipLabels.default'
    }
    variant={deliveryMethodId ? CheckDelaysIconVariant.WARNING : CheckDelaysIconVariant.INFO}
    sx={{
      position: 'relative',
      top: 'ds.sm',
      ml: 'ds.sm',
    }}
  />
);

const DeliveryMethodDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DeliveryMethodDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const DeliveryMethod = styled.div`
  cursor: pointer;
  max-width: 45rem;
  width: 100%;
  min-height: 8.4rem;
  border-radius: 0.8rem;
  border: ${(props) => `0.1rem solid ${props.isSelected ? props.theme.colors.primary.opaque : 'transparent'}`};
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.primary.translucent1 : props.theme.colors.white.opaque};
  padding: 2rem;
  box-sizing: border-box;
  box-shadow: ${(props) => `0 0.2rem 1rem 0 ${props.theme.colors.shadow.lightGrey}`};
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  transition: ${(props) => props.theme.animation.transition.default};
  transition-property: box-shadow;
  ${(props) => props.theme?.pages?.PayBillSelectDeliveryMethodPage?.DeliveryMethod};

  &:hover:not(:active) {
    ${(props) =>
      !props.isSelected &&
      css`
        box-shadow: 0 0.2rem 1rem 0 rgba(192, 196, 203, 1);
      `}
  }

  &:first-child {
    margin-top: 0;
  }
`;

const DeliveryMethodInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: ${(props) => props.theme.text.weight.normal};
  width: 90%;
  ${(props) => props.theme.text.fontType.medium};
`;

const DeliveryMethodDescription = styled.div`
  color: ${(props) => props.theme.text.color.label};

  ${(props) => props.theme?.components?.QBODeliveryMethodsListItem?.DeliveryMethodDescription}
`;

const DeliveryMethodIcon = styled.i`
  font-size: 4rem;
  color: ${(props) => (props.isSelected ? props.theme.colors.brand : 'rgba(107, 108, 114, 1)')};
  margin-right: 2rem;
  ${(props) => props.theme?.pages?.PayBillSelectDeliveryMethodPage?.DeliveryMethodIcon};
`;

const DeliveryMethodName = styled.span`
  color: ${(props) => props.theme.text.color.main};

  ${(props) => props.theme?.components?.QBODeliveryMethodsListItem?.DeliveryMethodName}
`;
