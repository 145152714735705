import React from 'react';
import { Box, Flex, Tooltip } from '@melio/billpay-design-system';
import {
  getPaymentMethodIcon,
  getShortPaymentMethodLabel,
} from 'src/billpay/qbdt/pages/batch-pay/components/menu/fundingSource/utils';
import { MIFormattedText } from 'src/utils/formatting';
import styled from 'styled-components';
import { AccountType } from 'src/utils/types';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types/store-types';
import FundingSourceIcon from 'src/billpay/qbdt/pages/batch-pay/components/menu/fundingSource/FundingSourceIcon';
import { useAmexVerificationContext } from 'src/billpay/qbdt/context/AmexVerificationContext';
import { isAMEXCard, isVisaCard } from 'src/utils/card';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlags } from 'src/utils/feature-flags';
import { useVisaVerificationContext } from 'src/billpay/qbdt/context/VisaVerificationContext';

type Props = {
  payment: BatchPaymentType;
  fundingSource: AccountType;
  isMenuOpen: boolean;
};

const FundingSourceCellLabel = ({ payment, fundingSource, isMenuOpen }: Props) => {
  const { openAmexModal } = useAmexVerificationContext();
  const { openVisaModal } = useVisaVerificationContext();
  const [visaMccVerificationFlag] = featureFlags.useFeature(FeatureFlags.VisaMccVerification, false);

  const { icon, imageSrc } = getPaymentMethodIcon(fundingSource);
  const { label, values, nickname } = getShortPaymentMethodLabel(fundingSource);
  const isAmexMCCMissing = isAMEXCard(fundingSource) && !payment.vendor?.mccCode;
  const isVisaMCCMissing = visaMccVerificationFlag && isVisaCard(fundingSource) && !payment.vendor?.mccCode;

  const handleVerifyAmexMCCClick = (event) => {
    if (!isMenuOpen) {
      event.stopPropagation();
      openAmexModal({
        billIds: payment.bills.map(({ id }) => Number(id)),
        vendorId: Number(payment.vendorId),
        vendorName: payment.vendor?.companyName,
      });
    }
  };

  const handleVerifyVisaMCCClick = (event) => {
    if (!isMenuOpen) {
      event.stopPropagation();
      openVisaModal({
        billIds: payment.bills.map(({ id }) => Number(id)),
        vendorId: Number(payment.vendorId),
        vendorName: payment.vendor?.companyName,
        deliveryMethods: payment.vendor?.deliveryMethods || [],
      });
    }
  };

  return (
    <Flex data-testid={`funding-source-cell-label-${fundingSource.id}`} align="center" w="calc(100% - 1.6rem)">
      <FundingSourceIcon imageSrc={imageSrc} icon={icon} size="1.6rem" />
      <Label>{nickname || <MIFormattedText label={label} values={values} />}</Label>
      {(isAmexMCCMissing || isVisaMCCMissing) && (
        <Tooltip
          placement="top"
          label={
            <MIFormattedText
              label={
                isAmexMCCMissing
                  ? 'regularBatchPayments.tooltips.missingAmexMCC'
                  : 'regularBatchPayments.tooltips.missingVisaMCC'
              }
            />
          }
        >
          <Box
            as="i"
            className="icon-warning-inline-icon"
            fontSize="1.6rem"
            ml={['1%', '1%', '1%', '1.2rem']}
            mb="0.2rem"
            color="#FFB300"
            onClick={isAmexMCCMissing ? handleVerifyAmexMCCClick : handleVerifyVisaMCCClick}
          />
        </Tooltip>
      )}
    </Flex>
  );
};

export default FundingSourceCellLabel;

const Label = styled.div`
  padding-left: 0.8rem;
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme.text.fontType.hint};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
