import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import moment from 'moment';
import { RecordOf } from 'immutable';
import { DashboardListItemFundingSourceType } from 'src/billpay/qbdt/pages/dashboard/types';
import { CONSTS, CARD_PROVIDERS } from './consts';
import visa from '../images/icons/visa.svg';
import mastercard from '../images/icons/mastercard.svg';
import americanexpress from '../images/icons/amex.svg';
import diners from '../images/icons/diners.svg';
import { CardTypes, AccountType } from './types';

const icons = { visa, mastercard, americanexpress, diners };

const getCardIconName = (fs) =>
  get(fs, 'cardAccount.cardType', CONSTS.CARD_TYPES.CREDIT) === CONSTS.CARD_TYPES.CREDIT
    ? 'icon-credit-card-icon'
    : 'icon-debit-card-icon';

export const getCardLogo = (cardType: CardTypes | undefined, network: string) => {
  const icon = CONSTS.CARD_NETWORK_TYPES[network];

  if (icon) {
    return <Image src={icons[icon]} alt={cardType} />;
  }

  return <FundingSourceIcon className={`icon-${cardType}-card-icon`} />;
};

export const getCardImageUrl = (fs?: AccountType | DashboardListItemFundingSourceType) => {
  if (!fs) return '';

  const cardName = get(fs, 'cardAccount.network', '').toUpperCase();
  const icon = CONSTS.CARD_NETWORK_TYPES[cardName];
  if (icon) return icons[icon];

  return '';
};

export const getCardIcon = (fs: AccountType) => (getCardImageUrl(fs) !== '' ? '' : getCardIconName(fs));

export const getIconCardLogo = (icn: string) => <FundingSourceIcon className={`icon-${icn}-icon`} />;

const FundingSourceIcon = styled.i`
  font-size: 3rem;
  color: ${(props) => props.theme.colors.icons.card};
`;

const Image = styled.img`
  height: 4rem;
`;

export const isAMEXCard = (fundingSource?: RecordOf<AccountType> | AccountType | null) =>
  fundingSource?.cardAccount?.network === CARD_PROVIDERS.AMEX;

export const isVisaCard = (fundingSource?: RecordOf<AccountType> | AccountType | null) =>
  fundingSource?.cardAccount?.network === CARD_PROVIDERS.VISA;

export const isCardExpired = (card: Record<string, any>, dateToCompare: Record<string, any> = moment()) => {
  const convertedExpirationDate = moment(card?.expiration, 'MM-YY').add(1, 'M');
  return moment(convertedExpirationDate).isBefore(moment(dateToCompare));
};

const getConvertedDate = (date: string) => moment(date, 'MM/YYYY').toDate();

export const convertDateToMonthYearFormat = (date: string) => moment(getConvertedDate(date)).format('MMM YYYY');

export const getExpirationDateHint = (card: Record<string, any>) => {
  let hint = '';
  if (isCardExpired(card)) {
    hint = 'bills.pay.fundingSource.expired';
  } else if (moment(getConvertedDate(card?.expiration)).isSame(moment(), 'month')) {
    hint = 'bills.pay.fundingSource.expirationDateLabel';
  }

  return hint;
};
