/* eslint-disable max-len */

export default {
  'en-US': {
    entry: {
      header: 'Bill Pay is loading, get ready to...',
      messages: {
        quicklyPay: 'quickly pay any vendor online',
        payWithACH: 'pay using bank transfers for free',
        extendFloat: 'extend float by paying with your credit card',
        scheduleBill: 'schedule bill payments to arrive right on time',
      },
    },
    nav: {
      mobileHeader: {
        bills: 'Bill pay',
      },
    },
    list: {
      group: {
        bill: {
          failedPayment: 'Payment failed',
          billsDueThisWeek: 'Due this week',
          billsForLater: 'Due later',
          listItemStatus: {
            failed: 'payment failed',
          },
        },
      },
      tab: {
        bills: {
          unpaid: 'unpaid',
        },
      },
    },
    batchPayment: {
      completeLegalInfo: {
        action: 'Save and schedule payments',
      },
      balanceConfirm: {
        title: 'Payment exceeds current balance',
        description:
          'This payment exceeds the current balance of your QuickBooks Cash account. Please make sure your account has enough funds for this payment so that it doesn’t fail.',
        titlePlural: 'These payments exceed your balance',
        descriptionPlural:
          'The sum total of these payments exceeds the balance in your QuickBooks Cash account. Please make sure your account has enough funds for these scheduled payments so that they don’t fail.',
        confirm: 'Schedule anyway',
        cancel: 'Edit payment',
      },
      paymentListTitle: {
        vendor: 'Vendor',
        deductionDate: 'Deduction date',
        deliveryDate: 'Delivery date',
        paymentMethod: 'Payment method',
        deliveryMethod: 'Delivery method',
        amount: 'Amount',
        achLabel: '...{digits}',
        bank: 'Bank',
        check: 'Check',
      },
    },
    viewBillPaymentActivity: {
      payFromTitle: 'Pay from',
      paidFromTitle: 'Paid from',
      fundingSource: {
        debitCardLabel: 'Debit card',
        unverifiedBankAccountHint: 'Pending verification',
        unverifiedBankAccountHintWithAction: 'Pending verification',
      },
      deliveryMethod: {
        paperCheckLabel: 'Paper check will be delivered to',
        achLabel: 'Bank transfer ACH to',
      },
      deliveryDate: {
        deliveryEtaLabel: 'Estimated payment delivery',
      },
    },
    viewBillWarningNotificationCard: {
      contributor: {
        unverifiedBankAccountWithActionMessage:
          'To pay on schedule, verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb>{br}<verifyLink>Verify bank account</verifyLink>',
      },
      admin: {
        unverifiedBankAccountWithActionMessage:
          'To pay on schedule, verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb>{br}<verifyLink>Verify bank account</verifyLink>',
      },
    },
    payBillPaymentActivity: {
      payFromTitle: 'Pay from my',
      fundingSource: {
        debitCardLabel: 'Debit card',
      },
      deliveryMethod: {
        paperCheckLabel: 'Paper check will be delivered to',
        achLabel: 'Bank transfer ACH to',
        virtualCardLabel: 'Single-use virtual card to',
        virtualCardTooltip: 'This vendor has opted to receive payments by single-use card.',
      },
      deliveryDate: {
        deliveryEtaLabel: 'Estimated payment delivery',
      },
    },
    bills: {
      actions: {
        pay: 'Schedule payment',
        payOnline: 'Schedule payments online ({amount})',
      },
      emptyState: {
        textScheduled: 'No bills scheduled for payment.',
        textPaid: 'No paid bills yet.',
        btnHint: 'No bills to pay.',
        btnText: 'Tap (+) to add a bill.',
      },
      new: {
        saveAndAdd: 'Save and schedule payment',
        createOptions: {
          subTitle: 'Add a bill however you like and schedule an online payment.',
          addBillManually: 'Manually',
          addBillManuallyDescription: 'Type in bill’s details',
          scanBill: 'Scan a bill',
          scanBillDescription: 'Use your device’s camera',
          importBillFile: 'From a file',
          importBillFileDescription: 'Upload a file from your device',
        },
        success: {
          done: 'I’m done here',
        },
      },
      form: {
        partialPayments: {
          total: 'Bill amount',
          billListItem: {
            totalAmount: 'Total bill amount',
            tag: 'Partially paid',
          },
        },
        paymentActivity: {
          paymentsMade:
            '{count} {count, plural, one {payment} other {payments}} made (<private>{amount, number, currency}</private>)',
          scheduledBill: {
            deliveryPaperCheckMethod: 'Paper check will be delivered to',
            scheduleMethodDebitCard: 'Debit card',
            deliveryBankTransferMethod: 'Bank transfer ACH to',
            balance: 'Current balance: <private>{balance, number, currency}</private>',
            freeBalance: 'Free. Current balance: <private>{balance, number, currency}</private>',
            noBalance: ' ',
            hint: 'Free. ',
          },
          actions: {
            edit: 'Edit payment',
            cancel: 'Cancel payment',
            close: 'Close',
            markAsUnpaid: 'Mark as unpaid',
          },
          paidFromMy: 'Paid from',
          payFromMy: 'Pay from',
          deliveryTitleETA: 'Estimated payment delivery',
          deliveryMethodAdditionalDescriptionPayee: '(By 8 PM local time)',
          failedPaymentDescription: {
            default:
              'Payment failed due to card network restriction. Please connect another payment method and retry to complete this payment.',
            incorrectVendorACH:
              'Please correct the payment receiver’s routing number (ACH/direct deposit, not wire) and notify us at support@qbousers.meliopayments.com so we can quickly process this payment.',
          },
        },
        attachment: {
          upload: 'Snap or upload bill',
        },
        totalAmount: 'Amount',
        billNumberField: 'Bill #',
        invoiceNumberEmpty: 'None',
        intuitAccountPlaceholder: 'Category',
        description: 'Note to self',
        deleteDialog: {
          title: 'Delete bill?',
          subtitle: 'You asked to delete a {totalAmount} bill associated with your vendor {companyName}.',
          confirm: 'Delete',
        },
      },
      status: {
        paymentManuallyPaid: 'Fully paid',
      },
      pay: {
        batch: {
          addFundingSource: {
            title: 'Choose how you want to pay these bills',
            subtitle: 'Connect a bank account or add a card.',
          },
        },
        deliveryMethods: {
          title: 'How would <b>{vendor}</b> like to receive this payment?',
        },
        confirm: {
          feeTerm: 'Charged separately to your account.',
          total: 'Amount',
          payTo: 'Paid to',
          balanceConfirm: {
            title: 'Payment exceeds current balance',
            description:
              'This payment exceeds the current balance of your QuickBooks Cash account. Please make sure your account has enough funds for this payment so that it doesn’t fail.',
            confirm: 'Schedule anyway',
            cancel: 'Edit payment',
          },
        },
        date: {
          deliveryOptions: {
            subTitle: {
              ach: '3 business days',
            },
          },
        },
        payBillSuccess: {
          buttonLabel: {
            toDashboard: 'Done',
            viewQBDashboard: 'View my Online Bill Payments',
            backQBDashboard: 'Back to my Online Bill Payments',
            done: 'I’m done here',
          },
          subtitles: {
            notification:
              'For this payment to transfer smoothly, please make sure there are available funds in your QuickBooks Cash account by <sb>{processDate}, at 12pm EST.</sb>',
          },
        },
        success: {
          regular: {
            title: 'Payment scheduled!',
            subtitle:
              'Your {amount} payment to {vendorName} was successfully scheduled and the bill was marked as paid.{br}To edit/cancel the payment go to the corresponding Bill Payment record and click “View online payment”',
          },
          notVerified: {
            title: 'Payment scheduled!',
            subtitle:
              'Your {amount} payment has been set to be processed on {processDate}.{br}To edit/cancel the payment go to the corresponding Bill Payment record and click “View online payment”{br}{br}Please note that while scheduled, our payment process is waiting for your bank’s verification. This typically takes 2 business days. Once verified, payment can begin right away. This delay applies to this first payment only.',
          },
          doneHere: 'I’m done here',
          buttonLabel: {
            toDashboard: 'Done',
          },
          titles: {
            fastAch: '<b>Expedited payment scheduled!</b>',
          },
          subtitles: {
            regular: 'Your <b>{amount}</b> payment to <b>{vendorName}</b> was successfully scheduled.',
            fastAch: {
              regular: 'Your <b>{amount}</b> to <b>{vendorName}</b> was successfully scheduled.',
            },
          },
        },
        collectVendorEmailModal: {
          confirm: 'OK. I’m done',
          subTitle:
            'Once your payment goes out we’ll email {vendorName} with your payment’s details to let them know when and how to expect it.',
          sendEmail: 'Send email notification',
          successTitle: 'Email sent!',
          successSubTitle:
            'We also added this email to your vendor’s details so you won’t have to worry about next time.',
        },
      },
      view: {
        due: 'Due on {date}',
        headerTitle: 'New bill',
      },
      labelPaidTab: {
        title: 'Don’t see all your paid bills?',
        subTitle: 'If you marked bills as paid on the web, head to the web to see them.',
      },
      errors: {
        sync: {
          title: 'Sorry, something went wrong',
          subTitle: 'We couldn’t load Bill Pay services. Please try again or reach out to us via chat for assistance',
          buttonLabel: 'Start chat',
        },
      },
    },
    progress: {
      continue: 'Continue',
      continueDDate: 'Next: Choose how vendor gets paid',
    },
    requests: {
      new: {
        success: {
          subTitle:
            'Share your payment request link with your customer. Send it over email or just copy the link below and share it in any other way you want.',
        },
      },
      form: {
        seen: 'last seen at ',
        paymentActivity: {
          deliveryPaperCheckMethod: 'Paper check will be delivered to',
          emptyNoteFromCustomerLabel: 'no note from customer',
        },
      },
      emptyState: {
        createNewPaymentRequest: 'Create new payment request',
      },
    },
    vendors: {
      title: 'Edit vendor',
      edit: {
        done: 'Save',
        title: 'Edit vendor',
      },
      addDeliveryMethodByLink: {
        subTitle: 'sent you an online payment of',
        for: 'For invoice no.',
        checkTitle: 'Enter your information so QuickBooks can mail you a paper check',
        manualAddressSubTitle:
          'We spotted a small issue with the address you provided, and suggested an alternative below. Please select and/or edit the final version you’d like to use.',
        success: {
          subtitle: {
            withoutInvoice: 'Your <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> is on its way.',
            regular:
              'Your <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> for <sb>Invoice {invoiceNumber}</sb> is on its way.',
            bulkPayment:
              'Your <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> for <sb>{invoiceNumber}</sb> is on its way.',
          },
          joinService: {
            title: 'Your bank details have been received',
            subTitle:
              'All future payments sent to you via QuickBooks Bill Pay will be directly deposited to your bank account ending in {digits}',
            dialog: {
              title: 'Wasn’t that easy? Try it yourself:{br}QuickBooks Bill Pay.',
              subTitle:
                'This payment is being processed with QuickBooks Bill Pay powered by Melio, a simple online payment solution for businesses like yours to send bank transfers and paper checks for free. Not a QuickBooks Online user yet? Join now and get started.',
            },
            button: 'Join QuickBooks Online',
            termsOfService: 'terms of service',
            privacyPolicy: 'privacy policy',
          },
        },
      },
      create: {
        name: {
          inputTitle: 'Business name',
        },
        email: {
          inputTitle: 'Business email',
        },
      },
      deliveryMethods: {
        check: {
          submit: 'Submit',
          name: {
            title: 'Who’s receiving the checks we’ll send?',
            label: 'Name to appear on the check',
          },
          address: {
            title: 'Where should we mail the check to?',
            confirmSubTitleLine2: 'Please select and/or edit the final version you’d like to use.',
          },
        },
        ach: {
          achDetails: {
            title: 'Want to get paid faster?',
            subTitle:
              'Enter your bank details to get your future payments deposited directly into your bank account for free, in just 1-3 business days.',
            formTitle: 'Enter your bank account’s ACH details',
            buttonSave: 'Save bank details',
            buttonEdit: 'Edit bank details',
            info:
              'By continuing, you agree to the Bill Pay powered by Melio {termsOfService} ' +
              'and {privacyPolicy}, you consent to Intuit sharing your QuickBooks data ' +
              'with Melio for purposes of the Bill Pay service and consent to Intuit ' +
              'and its subsidiaries’ use of your Bill Pay data subject to ' +
              'our {intuitPrivacyPolicy}.',
            footerText: 'By continuing you agree to Melio’s terms of service and privacy policy',
          },
          changeSuccess: {
            title: 'Your bank details have been received',
            subTitle:
              'All future payments sent to you via QuickBooks Bill Pay will be directly deposited to your bank account ending in {digits}',
            dialog: {
              title: 'Wasn’t that easy? Try it yourself:{br}QuickBooks Bill Pay.',
              subTitle:
                'This payment is being processed with QuickBooks Bill Pay powered by Melio, a simple online payment solution for businesses like yours to send bank transfers and paper checks for free. Not a QuickBooks Online user yet? Join now and get started.',
            },
            button: 'Join QuickBooks Online',
            termsOfService: 'terms of service',
            privacyPolicy: 'privacy policy',
            intuitPrivacyPolicy: 'privacy statement',
          },
        },
        shiftToDebit: {
          info:
            'By continuing you agree to our {termsOfService} and {privacyPolicy} which enable {companyName} to deposit their payments to your bank account.',
          notSupportedSubtitle: 'Credit cards are not supported for instant payments, you can only use a debit card.',
          success: {
            dialog: {
              title: 'Wasn’t that easy? Try it yourself: QuickBooks Bill Pay.',
              subTitle:
                'This payment is being processed with QuickBooks Bill Pay powered by Melio, a simple online payment solution for businesses like yours to send bank transfers and paper checks for free. Not a QuickBooks Online user yet? Join now and get started.',
            },
            button: 'Join QuickBooks Online',
            description: '*In a case where there is an issue with your card we will notify you and suggest next steps.',
          },
          errors: {
            paymentCancelled:
              'We’re sorry but this payment was cancelled by <sb>{companyName}</sb>. Please reach out to <sb>{companyName}</sb> at {email} and ask them to resend their request.{br}{br}If you need any help we`re available at {supportEmail}',
            paymentCompletedError: {
              title: 'ACH was already processed',
              subtitle:
                'Sorry but this payment was already processed and therefore you can not receive this payment instantly.{br}{br} If you need any help we`re available at {supportEmail}',
            },
          },
          processed: {
            title: 'Success!',
          },
        },
        virtual: {
          header: 'Ask vendor for payment details',
          subtitle: 'Enter <b><ap>{vendor}</ap></b> email and we’ll ask how they’d like to receive this payment.',
          footer:
            'If <b>{vendor}</b> doesn’t respond to our email within 7 business days this payment will be cancelled.',
        },
      },
    },
    payments: {
      form: {
        deleteDialog: {
          title: 'Cancel payment?',
          subtitle: 'You asked to cancel a {amount} payment to {companyName}.',
          confirm: 'Yes',
          cancelText: 'Close',
          removalFailedTitle: 'This payment method can’t be deleted.',
          paymentMethodNeededTitle: 'A payment method is needed',
          failed: {
            title: 'Can’t delete this payment method yet',
            scheduledPaymentsSubtitle: 'Cancel all scheduled payments where this method is used and try again.',
            failedPaymentsSubtitle: 'Cancel all failed and scheduled payments where this method is used and try again.',
          },
        },
      },
    },
    deliveryMethods: {
      notDefinedInfo: {
        ach: {
          label: 'Bank transfer (ACH) {to}',
          desc: '$0.50 fee when paying via ACH',
        },
        check: {
          label: 'Paper check {to}',
          desc: 'Free. No additional fee.',
        },
      },
    },
    onboarding: {
      companyInfo: {
        verify: {
          title: 'Before continuing',
          subTitle: 'Please complete the following information about your business to pay your first bill.',
          action: 'Save and continue',
        },
      },
      fundingSources: {
        options: {
          bank: {
            settingsDescription: 'Use your bank account as a payment method.',
            description: 'Use your bank account as a payment method.',
          },
          debit: {
            description: 'Free',
          },
        },
        bank: {
          manually: {
            connectSuccessTitle: 'Pending bank account verification',
            connectSuccessSubTitleBoldText: 'What happens next?',
            saveFooter:
              'We’ll keep your information safely stored in your profile using secure encryption for future use.',
            buttonCTA: 'Ok. Got it',
          },
          intuitAccounts: {
            title: 'Link activity to matching {type}',
            subtitle: 'Select the matching payment account to link it and sync its payment activity',
          },
        },
      },
      accountingSoftware: {
        success: {
          exit: 'I’ll do it later',
        },
      },
    },
    guests: {
      register: {
        paymentMethods: 'Payment methods we support: {bankAndCreditCard} or {debitCard}',
        securityText:
          'We keep all your financial details protected so no one sees your sensitive information. Your card/account details will be stored in your profile as a convenience for future use.',
      },
    },
    notFound: {
      title: '404',
      text: 'Page not found!',
    },
    settings: {
      account: {
        title: 'Account',
        form: {
          billQbSyncedNotification: 'Remind me to schedule payments for outstanding bills',
        },
      },
      paymentMethods: {
        title: 'Payment methods',
        description: 'Add/remove or edit all your payment methods - bank accounts, credit or debit cards.',
        delete: 'Delete',
        edit: 'Edit',
        view: 'View',
        ach: 'Bank account ',
        hint: 'Add this method',
        bankHint: 'ACH. Fast and reliable (free)',
        notVerifiedManualBankHint: 'Pending verification',
        creditCardHint: '{fees_credit_value}% Fee',
        debitCardHint: 'Free',
        addAnotherBank: '+ Add another bank account',
        addAnotherCreditCard: '+ Add another credit card',
        addAnotherDebitCard: '+ Add another debit card',
        credit: 'Credit card ',
        debit: 'Debit card ',
        deleteDialog: {
          title: 'Delete payment method?',
          subtitle: 'You asked to delete payment method {paymentMethodName} ending in {paymentMethodNumber}',
          confirm: 'Delete',
        },
        notVerifiedAccountHintWithAction: 'Pending verification*{br}{link}',
        microDepositsBlocked: 'Unable to verify your account. {chatWithUs}',
        microDepositsBlockedContactLabel: 'Contact support',
      },
      microDeposits: {
        verifyDialog: {
          title: 'Please enter the amounts you’ve received',
          subtitle: 'Check your bank account for two small deposits and submit them here to verify your account.',
          sum1Label: 'Amount #1',
          sum2Label: 'Amount #2',
        },
        verifyDialogLimitReached: {
          title: 'Too many failed attempts',
          subtitle: 'To verify your account, please contact our support team at {supportEmail} or {chatWithUs}.',
          subtitleWithoutChat: 'To verify your account, please contact our support team at {supportEmail}.',
          dismiss: 'Close',
        },
        verifyDialogSuccess: {
          ok: 'OK. Let’s get started',
        },
        verifyDialogLinkExpired: {
          title: 'Verification link has expired',
          subtitle:
            'Verification links expire after 7 days. To verify manually, contact{br}our support team at {supportEmail}',
        },
      },
      billing: {
        emptyTablePlaceholder: 'Receipts for fees due to Intuit will appear here.',
      },
    },
    server: {
      VT1: 'We’re sorry, looks like your link is not valid.',
      UNP1: 'We’re sorry, looks like your link is not valid.',
      UNP4: 'We’re sorry but this payment was cancelled. {br}Need help? We are always available at {supportEmail}',
      UNP5:
        'This payment link has expired. Please contact <sb>{companyName}</sb> at {email} to resolve this and get an updated link.',
      PMT18:
        'This payment link has expired. Please contact <sb>{companyName}</sb> at {email} to resolve this and get an updated link.',
      PMT19:
        'Sorry, but this payment can’t be processed instantly anymore. It’ll be processed as a free ACH bank transfer. {br}{br} If you need any help we`re available at {supportEmail}',
    },
    drawer: {
      title: 'Frequently asked questions',
      contactLabel: 'If you still have questions, please contact our support team',
      emailLabel: 'Email ',
      supportEmailAddress: ' {supportEmail} ',
      orLabel: ' or ',
      chatLabel: 'start a chat',
    },
    fundingSources: {
      microDeposits: {
        complete: 'Done',
      },
    },
    inputErrors: {
      deliveryMethod: {
        bankAccount: {
          routingNumber: {
            string: {
              regex: {
                base: 'This should be 9 digits',
              },
            },
          },
          accountNumber: {
            string: {
              regex: {
                base: 'This should be 6-17 digits',
              },
            },
          },
        },
        cardAccount: {
          printName: {
            array: {
              min: 'Please enter the recipient’s name',
            },
          },
          googlePlaceId: {
            any: {
              empty: 'Please enter the address',
              required: 'Please enter the address',
            },
          },
          addressLine1: {
            string: {
              min: 'Please enter a delivery address',
            },
          },
          city: {
            any: {
              empty: 'Please enter a city',
              required: 'Please select a city',
            },
          },
          state: {
            any: {
              empty: 'Please select a state',
              required: 'Please select a state',
              allowOnly: 'Please select a state',
            },
          },
          zipCode: {
            string: {
              regex: {
                base: 'Please enter a 5 or 9 digit zip code',
              },
            },
          },
        },
      },
      QBO01: {
        title: 'Sorry, something went wrong',
        subTitle:
          'We couldn’t load Bill Pay services but we’re working hard to fix it.{br}You can refresh this page or try again later.',
        buttonCTA: 'Take me back',
      },
    },
    paymentDashboard: {
      title: 'Online Bill Payments',
      tabs: {
        unpaid: 'Unpaid',
        scheduled: 'Scheduled',
        paid: 'Paid',
      },
      pagination: {
        first: 'First',
        last: 'Last',
        previous: 'Previous',
        next: 'Next',
        info: '{itemFromIndex}-{itemToIndex} of {totalCount} {totalCount, plural, one {item} other {items}}',
      },
      sync: {
        indicator: 'Syncing bills',
      },
      search: {
        panel: {
          label: 'Total unpaid bills:<b>{amount}</b>',
          placeholder: 'Search',
          buttonLabel: 'Add bill',
        },
        noResults: {
          title: 'No results for "{term}"',
          description: 'Try searching for another term',
        },
      },
      filters: {
        mainButtonTitle: 'Filter',
        clearLabelMessage: 'Clear all',
        noResultsMessage: 'No results',
        syncingBills: 'Syncing bills',
        menuSearch: {
          placeholder: 'Search',
        },
        menuItems: {
          statusesInDashboard: {
            label: 'Status',
            options: {
              dueInOneWeek: 'Due in 1 week',
              overdue: 'Overdue',
              declined: 'Declined',
              failed: 'Failed',
              scheduled: 'Scheduled',
              bankUnverified: 'Verify account',
              pendingVendorDetails: 'Vendor details',
              sent: 'Sent',
              deposited: 'Deposited',
              open: 'Due later',
            },
          },
          vendors: {
            label: 'Vendors',
            options: { vendorName: '{vendorName}' },
          },
          dueDates: {
            label: 'Due date',
            options: {
              nextSevenDays: 'Next 7 days',
              thisMonth: 'This month',
              nextMonth: 'Next month',
              pastDue: 'Past due',
            },
          },
        },
      },
      footer: {
        title: 'Total to pay: <sb>{totalAmount}</sb>',
        markAsPaid: 'Mark as paid',
        payOnline: '{amount, plural, one {Schedule payment} other {Schedule payments}} online ({amount})',
        errorInvalidAmountsTooltip: 'Check that the amounts entered for each payment are valid',
      },
      columns: {
        vendor: {
          title: 'Vendor',
        },
        invoiceNumber: {
          title: 'Bill #',
          multipleBills: '{n} bills',
        },
        deductionDate: {
          title: 'Deducted on',
        },
        dueDate: {
          title: 'Due date',
        },
        deliveryEta: {
          title: 'Delivery eta',
        },
        amount: {
          title: 'Amount',
        },
        billAmount: {
          title: 'Bill amount',
        },
        paymentAmount: {
          title: 'Amount',
        },
        actions: {
          title: 'Actions',
          view: 'View payment',
          schedule: 'Schedule payment',
          cancel: 'Cancel payment',
          edit: 'Edit payment',
          verifyAccount: 'Verify bank',
          retryPayment: 'Retry payment',
          markAsPaid: 'Mark as paid',
          markAsUnpaid: 'Mark unpaid',
        },
        unpaidStatus: {
          title: 'Status',
          failed: 'Payment failed',
          overdue: 'Overdue',
          dueInOneWeek: 'Due in 1 week',
          dueLater: 'Due later',
        },
        scheduledStatus: {
          title: 'Status',
          unilateral: 'Vendor details',
          unilateralTooltip: 'Pending vendor delivery details',
          unverifiedBankAccountTooltip:
            'To pay on schedule, verify your bank account by {paymentScheduledDate} 1-2pm EST.',
          unverifiedBankAccount: 'Bank unverified',
          scheduled: 'Scheduled',
        },
        paymentMethod: {
          title: 'Payment method',
        },
        deliveryMethod: {
          title: 'Delivery method',
          ach: 'Bank transfer',
          virtual: 'Pending',
          check: 'Paper check',
          virtualCard: 'Single-use card',
          debitCard: 'Debit card',
          debitCardTooltip: 'Delivery method was modified by vendor',
        },
        paidStatus: {
          title: 'Status',
          sent: 'Sent',
          deposited: 'Deposited',
          markedAsPaid: 'Marked as paid',
        },
        payment: {
          title: 'Payment',
          amountTooBigErrorTooltip: 'Enter amount equal or lower than the bill open balance',
        },
        paidOn: {
          title: 'Paid on',
        },
        checkbox: {
          failedPaymentTooltip: 'Failed payments can’t be selected',
        },
        openBalance: {
          title: 'Open balance',
        },
      },
      notifications: {
        smallWidth: {
          message: 'Some columns are hidden from view. Expand this window or use a larger screen to view all.',
        },
      },
      listEmptyState: {
        scheduled: {
          title: 'No scheduled payments',
          description:
            'Schedule a payment for an unpaid bill and it will show up here. <link>Go to unpaid bills</link>',
        },
        paid: {
          title: 'No completed payments yet',
          description:
            'Schedule a payment for an unpaid bill and once it’s been paid it will show up here.{br}<link>Go to unpaid bills</link>',
        },
        unpaid: {
          title: 'No unpaid bills yet',
          description: 'Add bills to pay to your QuickBooks and view, edit or schedule{br}payments for them here.',
        },
        syncing: {
          title: 'Syncing your bills ',
          description:
            'Once the sync is complete, you can view, edit or schedule payments for the bills added to QuickBooks here.',
        },
      },
      microDeposits: {
        verifyDialogSuccessTitle: 'Bank account verified!',
        verifyDialogSuccessSubTitle: 'Payments made from this bank account will now be processed without interruption.',
        toastMessage: 'Bank account verified',
      },
      cancelPayment: {
        dialogTitle: 'Cancel this bill payment?',
        dialogSubTitle: 'You asked to cancel <sb>{amount}</sb> payment to <sb>{companyName}</sb>.',
        toastMessage: 'Payment cancelled',
      },
      syncCanceledPayment: {
        toastMessage:
          'The payment was previously canceled but was not updated in QuickBooks Desktop due to a sync issue',
      },
      notifyVendor: {
        dialogTitle: 'Let {companyName} know the payment is on its way!',
        dialogSubTitle:
          'Once the payment is processed, we’ll send them an email with the payment’s details, so they’ll know when to expect its arrival.',
        dialogInputLabel: 'Vendor’s email address',
        dialogInputPlaceholder: 'Email address',
        dialogCTA: 'Send email notification',
      },
      notifyVendorSuccess: {
        dialogTitle: 'Email sent!',
        dialogSubTitle:
          'We’ve also added this email to your vendor’s details. You can always change or edit it in your settings.',
      },
      schedulePayment: {
        toastMessage: {
          edit: 'Payment updated',
          pay: 'Payment scheduled',
          batch: '{count} payments scheduled',
        },
      },
      ftuModal: {
        title: 'Your bills and payments, now in one place!',
        ctaButton: 'Got it',
        footerText:
          'Bill Pay services powered by <b>Melio</b> with funds held by <b>Evolve Bank & Trust</b> or <b>Silicon Valley Bank</b> (members of the FDIC and the Federal Reserve)',
        items: {
          view: 'View your unpaid bills and payments',
          manage: 'Manage bill payments from start to finish',
          schedule: 'Schedule batch payments',
        },
      },
      markAsPaid: {
        toastMessageSingle: '1 payment marked as {meta_identifier_actionType}',
        toastMessageBatch: '{count} bills marked as paid',
        dialogTitle: 'Which account was used for this payment?',
        dialogTitleBatch: 'Which account was used for these payments?',
        intuitAccountLabel: 'Bank account in QuickBooks',
        intuitAccountPlaceholder: 'Select account',
        dialogOkButtonText: 'Save',
      },
      editVendor: {
        title: 'Contact Details',
        success: '{vendorName} details updated',
        name: 'Business name',
        email: 'Business email',
        save: 'Save',
      },
    },
    checkDelaysWarning: {
      tooltipLabels: {
        default: 'Checks may be delayed with USPS this holiday season',
        specificCheck: 'Your check may be delayed with USPS this holiday season',
        detailed: 'To make sure payment arrives by due date, switch the delivery method to ACH or select fast check.',
      },
      labels: {
        unilateral: {
          check: 'USPS expects delays',
        },
      },
      deductionDate: {
        warning: {
          title: 'Potential USPS holiday delays on checks',
          subtitle:
            'Switch to fast delivery so your check gets to your vendor on time this holiday season. <learnMoreLink>Learn more</learnMoreLink>',
        },
        info: {
          title: 'Check deliveries may be delayed this holiday season',
          subtitle:
            'To avoid delays or miss due dates, make sure to schedule your payment ahead of time. <learnMoreLink>Learn more</learnMoreLink>',
        },
      },
      batch: {
        notification: {
          title: 'Potential USPS holiday delays on checks',
          subtitle:
            'Switch to fast delivery so your check gets to your vendor on time this holiday season. <learnMoreLink>Learn more</learnMoreLink>',
        },
        notificationWithoutFastCheck: {
          title: 'Check deliveries may be delayed this holiday season',
          subtitle:
            'To avoid delays or miss due dates, make sure to schedule your payment ahead of time. <learnMoreLink>Learn more</learnMoreLink>',
        },
      },
    },
  },
};
