import React from 'react';
import styled from 'styled-components';
import { featureFlags } from '@melio/shared-web';
import { Flex } from '@melio/billpay-design-system';
import { getDeliveryMethodByType } from 'src/records/vendor';
import { DeliveryMethodType, VendorType } from 'src/utils/types';
import { MICardSection, MICardTitle } from 'src/components/common/MICard';
import { DELIVERY_TYPE, FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { MIFormattedText } from 'src/utils/formatting';
import { ThreeDotsMenu } from 'src/components/common/tree-dots-menu/ThreeDotsMenu';
import MIInlineLink from 'src/components/common/MIInlineLink';
import { CheckDelaysIcon, CheckDelaysIconVariant } from 'src/components/common/CheckDelaysIcon';
import { useCheckFee } from 'src/billpay/qbdt/hooks/useCheckFee';
import { FeatureFlags } from 'src/utils/feature-flags';
import { VirtualCardDeliveryMethodItem } from './VirtualCardDeliveryMethodItem';

type Props = {
  className?: string;
  vendor: VendorType;
  goEditDeliveryMethod: (deliveryMethod: DeliveryMethodType) => void;
};

const VendorDeliveryMethodsList = ({ className = '', vendor, goEditDeliveryMethod }: Props) => {
  const checkFee = useCheckFee();
  const [isCheckDelaysEnabled] = featureFlags.useFeature(FeatureFlags.CheckDelaysWarning, false);
  const ach = getDeliveryMethodByType(DELIVERY_TYPE.ACH, vendor.deliveryMethods);
  const check = getDeliveryMethodByType(DELIVERY_TYPE.CHECK, vendor.deliveryMethods);

  const deliveryMethodOptions = [ach, check];

  const getActionOptions = (dm) => [
    {
      label: 'deliveryMethods.actions.edit',
      action: () => goEditDeliveryMethod(dm),
    },
  ];

  const getDescription = (dm) => {
    if (dm.deliveryType === DELIVERY_TYPE.CHECK) {
      const { description, descriptionValues } = checkFee.getNotice();

      return { label: description, values: descriptionValues };
    }

    return {
      label: `deliveryMethods.notDefinedInfo.${dm.deliveryType}.desc`,
      values: undefined,
    };
  };

  const virtualCardDeliveryMethod = vendor.deliveryMethods.find(
    (deliveryMethod) => deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD
  );

  if (virtualCardDeliveryMethod) {
    return (
      <DeliveryMethodsCard className={className}>
        <VirtualCardDeliveryMethodItem deliveryMethod={virtualCardDeliveryMethod} />
      </DeliveryMethodsCard>
    );
  }

  return (
    <DeliveryMethodsCard className={className}>
      {deliveryMethodOptions.map((dm) => (
        <DeliveryMethod key={dm.deliveryType}>
          <DeliveryMethodDetailsContainer>
            <DeliveryMethodDescriptionContainer>
              <DeliveryMethodIcon
                className={dm.deliveryType === DELIVERY_TYPE.ACH ? 'icon-bank-icon' : 'icon-check-icon'}
              />
              <div>
                <DeliveryMethodName data-testId={`delivery-method-${dm.deliveryType}-name`}>
                  <MIFormattedText
                    label={`deliveryMethods.notDefinedInfo.${dm.deliveryType}.label`}
                    values={{
                      to: dm.id ? <MIFormattedText label="deliveryMethods.to" /> : '',
                    }}
                  />
                  {dm.id && (
                    <span className={FULL_STORY_MASK_RULE_CLASS}>{`(${dm.getDisplayName(vendor.companyName)})`}</span>
                  )}
                  {isCheckDelaysEnabled && dm?.deliveryType === DELIVERY_TYPE.CHECK && (
                    <CheckDelaysIcon
                      tooltipLabel="checkDelaysWarning.tooltipLabels.default"
                      variant={dm?.id ? CheckDelaysIconVariant.WARNING : CheckDelaysIconVariant.INFO}
                      sx={{
                        position: 'relative',
                        top: 'ds.sm',
                        ml: 'ds.sm',
                      }}
                    />
                  )}
                </DeliveryMethodName>
                <DeliveryMethodDescription>
                  <MIFormattedText label={getDescription(dm).label as string} values={getDescription(dm).values} />
                </DeliveryMethodDescription>
              </div>
            </DeliveryMethodDescriptionContainer>
            {dm.id && <ThreeDotsMenu actions={getActionOptions(dm)} />}
            {!dm.id && (
              <MIInlineLink
                testId={`add-new-dm-${dm.deliveryType}`}
                onClick={() => goEditDeliveryMethod(dm)}
                label="deliveryMethods.actions.addMethod"
              />
            )}
            <checkFee.Modal />
          </DeliveryMethodDetailsContainer>
        </DeliveryMethod>
      ))}
    </DeliveryMethodsCard>
  );
};

export default VendorDeliveryMethodsList;

const DeliveryMethodsCard = ({ className, children }) => (
  <MICardSection className={className}>
    <MICardTitle label="vendors.deliveryMethods.title" />
    <Flex direction="column" mt="ds.xl">
      {children}
    </Flex>
  </MICardSection>
);

const DeliveryMethodDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > button {
    white-space: nowrap;
  }
`;

const DeliveryMethodDescriptionContainer = styled.div`
  display: flex;
`;

const DeliveryMethod = styled.div`
  width: 100%;
  border-radius: 0.8rem;
  border: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};
  background-color: ${(props) => props.theme.colors.white.opaque};
  padding: 2.1rem 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 1.3rem;
  font-weight: ${(props) => props.theme.text.weight.normal};
  transition: ${(props) => props.theme.animation.transition.default};

  &:first-child {
    margin-top: 0;
  }
  ${(props) => props.theme.text.fontType.medium};
`;

const DeliveryMethodName = styled.div`
  color: ${(props) => props.theme.text.color.main};
`;

const DeliveryMethodDescription = styled.div`
  color: ${(props) => props.theme.text.color.label};
`;

const DeliveryMethodIcon = styled.i`
  font-size: 4rem;
  color: rgba(107, 108, 114, 1);
  padding-right: 1.4rem;
`;
