import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { featureFlags } from '@melio/shared-web';
import { generatePath } from 'react-router-dom';
import analytics from 'src/services/analytics';
import { catchAsync } from 'src/utils/async';
import errorTracker from 'src/utils/error-tracking';
import locations from 'src/billpay/qbdt/pages/locations';
import { getOrgId, getCompanyInfo } from 'src/redux/user/selectors';
import { getBill, getExitUrl, getPayment, getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import {
  createPaymentAction,
  updatePaymentAction,
  retryFailedPaymentAction,
  endPayBillFlowAction,
} from 'src/redux/payBillWizard/actions';
import useHistoryWithOrgId from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { PAYMENT_STATUS, KYB_STATUS } from 'src/utils/consts';
import { syncPayment, reportPaymentSummary, setCanClose } from 'src/billpay/qbdt/services/qbdt';
import { logger } from 'src/services/loggers';
import { PaymentType } from 'src/utils/types';
import { getPartialBillId } from 'src/utils/bills';
import { QBDTSDKError } from 'src/billpay/qbdt/services/qbdt/sdk';
import { TRANSACTION_LOCK_ERROR_CODES } from 'src/billpay/qbdt/services/qbdt/constants';
import { FeatureFlags } from 'src/utils/feature-flags';
import { useRedirectToDashboard } from '../../dashboard/hooks/useRedirectToDashboard';
import { DashboardActionConfirmFlowEnum, DASHBOARD_ACTION_CONFIRM_FLOW_STATE_PROP } from '../../dashboard/consts';

const eventPage = 'pay-bill';

type Params = {
  onNext: () => void;
};

const retryOptions = { retries: 3, retrySleep: 3000 };

export const useOnPaymentSubmit = ({ onNext }: Params) => {
  const [historyPush] = useHistoryWithOrgId();
  const [dashboardFeatureEnabled] = featureFlags.useFeature(FeatureFlags.Dashboard, false);

  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const orgId = useSelector(getOrgId);
  const companyInfo = useSelector(getCompanyInfo);
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const exitUrl = useSelector(getExitUrl);
  const [isPaymentSubmitting, setIsPaymentSubmitting] = useState(false);
  const dispatch = useDispatch();

  const { redirectToDashboard } = useRedirectToDashboard();

  const onSubmit = async () => {
    setIsPaymentSubmitting(true);
    await setCanClose(false);
    updateOrCreatePayment();
  };

  const updateOrCreatePayment = () => {
    const { payments } = bill;

    if (!payments.length) {
      createPayment();

      return;
    }

    const { status } = payments[payments.length - 1];

    if (status === PAYMENT_STATUS.FAILED) {
      reschedulePayment();

      return;
    }

    updatePayment();
  };

  const onSuccess = async (paymentId: string) => {
    await setCanClose(true);

    if (dashboardFeatureEnabled) {
      dispatch(endPayBillFlowAction());

      await redirectToDashboard({
        paymentId,
        state: {
          payment,
          bill,
          [DASHBOARD_ACTION_CONFIRM_FLOW_STATE_PROP]: DashboardActionConfirmFlowEnum.SinglePay,
        },
      });

      setIsPaymentSubmitting(false);

      return;
    }

    setIsPaymentSubmitting(false);
    onNext();
  };

  const onFailure = async ({ error, actionType, paymentId }) => {
    await setCanClose(true);
    errorTracker.capture(error as Error, { extra: actionType });
    analytics.track(eventPage, actionType);
    setIsPaymentSubmitting(false);
    const isTransactionLockError = error instanceof QBDTSDKError && TRANSACTION_LOCK_ERROR_CODES.includes(error.code);

    if (isTransactionLockError && dashboardFeatureEnabled) {
      logger.error('useOnPaymentSubmit.onFailure(): translaction lock error', error, { extra: actionType });
      dispatch(endPayBillFlowAction());

      await redirectToDashboard({
        paymentId,
        state: {
          payment,
          bill,
          [DASHBOARD_ACTION_CONFIRM_FLOW_STATE_PROP]: DashboardActionConfirmFlowEnum.SinglePay,
          isSyncPaymentFailed: true,
        },
      });

      return;
    }

    logger.error('useOnPaymentSubmit.onFailure(): failed', error, { extra: actionType });
    historyPush({
      path: generatePath(locations.pay.error, {
        orgId,
        billId: bill.id,
      }),
      state: {
        exitUrl,
      },
    });
  };

  const createPayment = async () => {
    analytics.track(eventPage, 'confirm', {
      fundingSourceId: selectedFundingSource.id,
    });
    let paymentId;

    try {
      setIsPaymentSubmitting(true);
      const createdPayment = await actionToPromise<PaymentType>(createPaymentAction, dispatch);

      paymentId = createdPayment.id;
      const paymentSummary = await syncPayment({
        orgId,
        paymentId,
        retryOptions,
      });

      if (!dashboardFeatureEnabled) {
        await reportPaymentSummary(paymentSummary);
      }

      analytics.track(eventPage, 'confirm-success');
      analytics.trackMqlEvent('created-payment', 'mql');

      if (companyInfo.kybStatus === KYB_STATUS.ACCEPTED) {
        analytics.track(eventPage, 'kybaccepted-confirm-success');
      }

      onSuccess(paymentId);
    } catch (error) {
      await onFailure({ error, actionType: 'confirm-failed', paymentId });
    }
  };

  const updatePayment = async () => {
    analytics.track(eventPage, 'update-confirm');

    try {
      await actionToPromise<PaymentType>(updatePaymentAction, dispatch);
      await syncPaymentAfterUpdate();

      analytics.track(eventPage, 'update-confirm-success');

      onSuccess(payment.id);
    } catch (error) {
      await onFailure({
        error,
        actionType: 'update-confirm-failed',
        paymentId: payment.id,
      });
    }
  };

  const reschedulePayment = async () => {
    analytics.track(eventPage, 'reschedule-confirm');

    const [errorReschedule] = await catchAsync(actionToPromise(retryFailedPaymentAction, dispatch));

    if (errorReschedule) {
      await onFailure({
        error: errorReschedule,
        actionType: 'reschedule-failed',
        paymentId: payment.id,
      });

      return;
    }

    const [errorSync] = await catchAsync(syncPaymentAfterUpdate());

    if (errorSync) {
      const actionType = 'reschedule-sync-failed';
      logger.error('useOnPaymentSubmit.reschedulePayment(): failed', errorSync, { extra: actionType });
      errorTracker.capture(errorSync as Error, { extra: actionType });
      analytics.track(eventPage, actionType);
      // We don't interrupt execution here
      // cause there could be an issue with closingDate setting
      // that could be set on QBDT side and doesn't allow us
      // to update payments(using SDK) before this date
    }

    analytics.track(eventPage, 'reschedule-confirm-success', {
      billId: bill.id,
      partialBillId: getPartialBillId(bill),
      paymentId: payment.id,
      deliveryMethodId: payment.deliveryMethodId,
    });
    analytics.trackMqlEvent('reschedule-payment', 'mql');

    await onSuccess(payment.id);
  };

  const syncPaymentAfterUpdate = async () => {
    const paymentSummary = await syncPayment({
      orgId,
      paymentId: payment.id,
      retryOptions,
    });

    if (!dashboardFeatureEnabled) {
      await reportPaymentSummary(paymentSummary);
    }
  };

  return {
    onSubmit,
    isPaymentSubmitting,
  };
};

const actionToPromise = <T>(actionFn, dispatch) =>
  new Promise<T>((resolve, reject) => {
    dispatch(actionFn(resolve, reject));
  });
